/* eslint-disable one-var */

import camelize from "../../../lib/camelize";

export const extractField = (source, key) => source.reduce(
  (acc, product) => {
    let value = product[key];
    if (camelize.validateObject(value)) {
      value = camelize.keysOfObject(value);
    }
    acc[String(product.product_descriptor_id)] = value;
    return acc;
  },
  {}
);

export const initializeFeedback = (shipment) => {
  const nextState = [
    "comments",
    "ratings"
  ].reduce(
    (acc, key) => {
      acc[key] = extractField(shipment.feedback, key);
      return acc;
    },
    {}
  );
  nextState.stylingRequestLink = shipment.next_shipment_styling_request_url;
  return nextState;
};
/* eslint-disable react/destructuring-assignment */
export const initializeStore = (props) => {
  const state = {
    "apiToken": props.apiToken,
    "customerId": props.customerId,
    "displayNewTierNotification": false,
    "error": {
      "displayError": false,
      "errorMessage": ""
    },
    "isLoggedOutHomepage": props ? props.params.controller === "roxy" && props.params.action === "index" : false,
    "postPurchaseModal": false,
    "promoCode": props.promoCode || "",
    "promotions": props.promotions,
    "warehouseClosed": props.warehouseClosed,
    "warehouseClosedShopPageText": props.warehouseClosedShopPageText,
    "warehouseReducedCapacityText": props.warehouseReducedCapacityText
  };
  // If we're on /account/feedback
  if (props && props.shipment && props.shipment.feedback) { // eslint-disable-line
    const {shipment} = props;
    state.Feedback = initializeFeedback(shipment);
    // Store this globally
    state.credit = shipment.credit;
  }
  return state;
};
/* eslint-enable react/destructuring-assignment */
