/* eslint-disable max-len, max-params, sort-keys */

export default class API {
  static API_TOKEN = null;

  static API_VERSION = 1;

  static CUSTOMER_ID = null;

  static ANONYMOUS_CONTACT_ID = null;

  static setApiToken (apiToken) {
    this.API_TOKEN = apiToken;
  }

  static setCustomerId (customerId) {
    this.CUSTOMER_ID = customerId;
  }

  static setAnonymousContactId (id) {
    this.ANONYMOUS_CONTACT_ID = id;
  }

  static authHeaders () {
    return (
      {
        "Authorization": `Token token=${this.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      }
    );
  }

  static fetchWishlist (offset, endpoint = "/jewelry", filters = {}, searchParam = "", count = 36) {
    return $.ajax({
      "method": "GET",
      "url": `${endpoint}/paginate`,
      "data": {
        count,
        filters,
        offset,
        "search": searchParam
      }
    });

  }

  static fetchProducts (list, {designerName, "id": productDescriptorId}) {
    return $.ajax({
      "method": "GET",
      "url": `/api/v${this.API_VERSION}/products?list=${list}&product_descriptor_id=${productDescriptorId}&customer_id=${this.CUSTOMER_ID}&designer=${designerName}`,
      "headers": {
        "Authorization": `Token token=${this.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      }
    });
  }

  static wishlist (productDescriptorId, feedWishlist) {
    return $.ajax({
      "method": "POST",
      "url": `/api/v${this.API_VERSION}/requests?product_id=${productDescriptorId}&anonymous_contact_id=${this.ANONYMOUS_CONTACT_ID}&customer_id=${this.CUSTOMER_ID}&channel=product_pages`,
      "headers": {
        "Authorization": `Token token=${this.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      }
    }).done(() => {
      window.analytics.track("Clicked WL star", {
        "channel": "product_pages",
        feedWishlist,
        "pd_id": productDescriptorId
      });
      this.updateWishListOnboardingUI(true);
    });
  }

  static unWishlist (productDescriptorId) {
    return $.ajax({
      "method": "PATCH",
      "url": `/api/v${this.API_VERSION}/requests/${productDescriptorId}?anonymous_contact_id=${this.ANONYMOUS_CONTACT_ID}&customer_id=${this.CUSTOMER_ID}`,
      "headers": {
        "Authorization": `Token token=${this.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      }
    }).done(() => this.updateWishListOnboardingUI(false));
  }

  static collectFilters () {
    const filterObj = {
      "colors": [],
      "designers": [],
      "holiday_ready": [],
      "kinds": [],
      "metals_material": [],
      "price_range": [],
      "promo_price": [],
      "sorts": [],
      "trends": []
    };
    $("input[type='checkbox']:checked").each((index, input) => {
      const $input = $(input);
      if (!$input.data("value")) {
        return;
      }
      filterObj[$input.data("filter")].push($input.data("value"));
    });

    return filterObj;
  }

  static wishlistRequestsNeeded = parseInt($("#requests_needed").val(), 10);

  static updateWishListOnboardingUI (add) {
    if (add) {
      this.wishlistRequestsNeeded--;
    } else {
      this.wishlistRequestsNeeded++;
    }
    if (this.wishlistRequestsNeeded === 0) {
      $(".prioritize-wishlist-callout").hide();
      $(".requests-needed .notification").hide();
      $("#prioritize-wl-modal").addClass("in").css({"display": "block"});
      $("#prioritize-wl-modal .close").click(() => {
        $("#prioritize-wl-modal").removeClass("in").css({"display": "none"});
      });
      $("#prioritize-wl-modal .back-to-wishlist").click(() => {
        $("#prioritize-wl-modal").removeClass("in").css({"display": "none"});
      });
      $(".wishlist-banner").hide();
    } else if (this.wishlistRequestsNeeded > 0) {
      if (this.wishlistRequestsNeeded === 10) {
        $(".prioritize-wishlist-callout").show();
        window.analytics.track("Showed prioritize wishlist callout");
      }
      $(".number-requests-needed").text(this.wishlistRequestsNeeded);
    }
  }

  static updateCurrentlyViewingText (filterObj) {
    let bestDeals = "";
    let featured = "";
    let filterStr = "";
    let kind = "";
    let priceRangeStr = "";
    let sortedBy = "";
    const filtersAndStrings = [
      {
        "filter": filterObj.kinds,
        "kind": "kinds",
        "str": ""
      },
      {
        "filter": filterObj.metals,
        "kind": "metals",
        "str": " in"
      },
      {
        "filter": filterObj.price_range,
        "kind": "price_range",
        "str": ""
      },
      {
        "filter": filterObj.holiday_ready,
        "kind": "holiday_ready",
        "str": ""
      },
      {
        "filter": filterObj.promo_price,
        "kind": "promo_price",
        "str": ""
      },
      {
        "filter": $(".designer-checkbox:checked").map(() => $(this).data("display-value")),
        "kind": "designers",
        "str": " by"
      }
    ];

    if (filterObj.trends[0]) {
      filterStr += ` ${filterObj.trends[0].replace(/_/g, " ")}`;
    }

    $.each(filtersAndStrings, (_index, filterAndString) => {
      if (filterAndString.filter.length === 0) {
        filterAndString.str = "";
      } else if (filterAndString.filter.length === 4 && filterAndString.kind !== "designers") {
        filterAndString.str = "";
      } else if (filterAndString.filter.length > 3 && filterAndString.kind === "designers") {
        filterAndString.str = " by Many Designers";
      } else {
        if (filterAndString.filter && filterAndString.kind === "price_range") {
          const bestDealIndex = filterAndString.filter.indexOf("best_deals");
          if (bestDealIndex !== -1) {
            bestDeals = " Best Deals";
            filterAndString.filter.splice(bestDealIndex, 1);
          }
        }

        $.each(filterAndString.filter, (index, _filter) => {
          let filter = _filter;
          filter = filter.replace(/_/g, " ");
          if (filterAndString.kind !== "designers") {
            const splitStr = filter.split(" ");
            filter = "";
            $.each(splitStr, (index, substr) => { // eslint-disable-line no-shadow
              if (index > 0) {
                filter += " ";
              }
              filter += substr.charAt(0).toUpperCase() + substr.slice(1);
            });
          }
          if (index === 0) {
            filterAndString.str += ` ${filter}`;
          } else if (index === filterAndString.filter.length - 1) {
            filterAndString.str += ` and ${filter}`;
          } else {
            filterAndString.str += `, ${filter}`;
          }
        });
      }

      if (filterAndString.kind === "price_range") {
        priceRangeStr = filterAndString.str;
      } else if (filterAndString.kind === "kinds") {
        kind += filterAndString.str;
      } else {
        filterStr += filterAndString.str;
      }
    });
    if (filterObj.sorts[0]) {
      if (filterObj.sorts[0] === "Featured") {
        featured = "Featured";
      } else {
        sortedBy = ` Sorted by ${filterObj.sorts[0]}`;
      }
    } else {
      featured = "Featured";
    }
    $(".currently-viewing").text(`: All ${bestDeals || featured}${kind || " Jewelry"}${filterStr}${priceRangeStr}${sortedBy}`);

    if (filterObj.colors.length > 0) {
      $(".colors").html("In ");
      $.each(filterObj.colors, (index, color) => {
        $(".colors").append(`<div class="color-circle ${color}" style="background-color: ${color}"></div>`);
      });
    } else {
      $(".colors").html("");
    }
  }
}
