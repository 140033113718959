/* eslint-disable max-len, max-params, sort-keys */
export default class Helper {
  static assetsUrl = "https://karat-herokuapp-com.global.ssl.fastly.net";

  static inExperimentVariant = (name) => {
    const experimentVariants = document.body.className;
    return experimentVariants.includes(name.toLowerCase());
  }

  static createProductObject = (response, sideEffects) =>
    response.reduce((products, product) => ({
      ...products,
      [product.id]: {
        "order": product.details.order || {},
        "productDescriptor": product,
        "wishlisted": product.details.wishlisted
      }
    }), sideEffects);

  static formatDate = (_date) => {
    const date = new Date(_date);
    return new Intl.DateTimeFormat("en-US", {
      "year": "2-digit",
      "month": "2-digit",
      "day": "2-digit"
    }).format(date);
  }

  static formatDateToString = (_date) => {
    const date = new Date(_date);
    return new Intl.DateTimeFormat("en-US", {
      "year": "numeric",
      "month": "long",
      "day": "2-digit"
    }).format(date);
  }

  static formatDateToStringShort = (_date) => {
    const date = new Date(_date);
    return new Intl.DateTimeFormat("en-US", {
      "year": "numeric",
      "month": "short",
      "day": "2-digit"
    }).format(date);
  }

  static formatDateToStringDDMonthYYYY = (_date) => {
    const date = new Date(_date);
    return new Intl.DateTimeFormat("en-GB", {
      "year": "numeric",
      "month": "long",
      "day": "2-digit"
    }).format(date);
  }

  // eslint-disable-next-line consistent-return
  static toTitleCase (str) {
    if (str) {
      const newArr = [];
      const strArr = str.split(" ");
      // eslint-disable-next-line id-length
      for (let i = 0; i < strArr.length; i++) {
        if (isNaN(Number(strArr[i]))) {
          newArr.push(strArr[i].charAt(0) + strArr[i].slice(1).toLowerCase());
        } else newArr.push(strArr[i]);
      }
      return newArr.join(" ");
    }
  }

  static countItemsSwapped (original, updated) {
    let count = 0;
    updated.forEach((product) => {
      if (!original.includes(product.id)) {
        count++;
      }
    });
    return count;
  }

  static snakecaseToTitle (snakecaseString) {
    const string = snakecaseString.split("_");

    return string.map(str => str[0].toUpperCase() + str.slice(1)).join(" ");
  }

  static formatPriceCents (priceCents) {
    if (isNaN(priceCents) && priceCents.includes("$")) {
      return parseInt(priceCents.substr(1)).toFixed(2);
    }

    return (priceCents / 100).toFixed(2);
  }

  static generateKey () {
    return Math.random().toString(36).substring(7);
  }

  static getCookieByName (name) {
    const cookieMatch = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);

    if (cookieMatch) {
      return cookieMatch.pop();
    }

    return null;
  }

  static formatDollarsForIterable (dollarString) {
    return parseFloat(dollarString.substring(1)).toFixed(2);
  }

  static formatCentsToPrice (priceCents) {
    if (isNaN(priceCents) && priceCents.includes("$")) {
      return parseInt(priceCents.substr(1)).toFixed(2);
    }

    let dollarFormat = (priceCents / 100).toFixed(2).toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(dollarFormat)) dollarFormat = dollarFormat.replace(pattern, "$1,$2");

    return dollarFormat;
  }

  static filterNamesFromObject (filterObject) {
    return Object.keys(filterObject).filter(item => filterObject[item]);
  }

  static oneYearFromNow () {
    return new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365));
  }

  static validateEmail (email) {
    if (!email) return false;

    const regEx = /[a-zA-Z0-9]{1,}[.][a-zA-Z0-9]{2,}$/;

    return email.match(/@/) && email.match(regEx);
  }

  static addSearchParamToUrl (params) {
    let paramsString = "";
    for (const [
      key,
      value
    ] of params) {
      paramsString += `${key}`;
      if (value) {
        paramsString += `=${value}`;
      }
      paramsString += "&";
    }
    return paramsString.slice(0, -1);
  }
}
