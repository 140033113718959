/* eslint-disable one-var */

import API from "../shared/modules/API";
import axios from "axios";

export const FETCH_ORDER_INFO = "FETCH_ORDER_INFO",
  fetchOrderInfo = (productDescriptorIds) => {
    let request = {"product_descriptor_ids": productDescriptorIds};
    if (API.CUSTOMER_ID) {
      productDescriptorIds.forEach((productDescriptorId) => {
        request = $.ajax({
          "headers": API.authHeaders(),
          "method": "GET",
          "url": `/api/v${API.API_VERSION}/product_descriptors/order_info?product_id=${productDescriptorId}`
        });
      });
    }

    return {
      "payload": request,
      "type": FETCH_ORDER_INFO
    };
  };

export const FETCH_BIN_ELIGIBILITY = "FETCH_BIN_ELIGIBILITY",
  fetchBuyItNowEligibility = (productDescriptorId) => {
    const request = $.ajax({
      "headers": API.authHeaders(),
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/product_descriptors/${productDescriptorId}/check_bin_eligibility`
    });

    return {
      "payload": request,
      "type": FETCH_BIN_ELIGIBILITY
    };
  };

export const CLEAR_BIN_ELIGIBILITY = "CLEAR_BIN_ELIGIBILITY",
  clearBuyItNowEligibility = () => ({
    "payload": {"eligibility": false},
    "type": CLEAR_BIN_ELIGIBILITY
  });

export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS", // eslint-disable-line
  clearAllFilters = () => ({
    "payload": {},
    "type": CLEAR_ALL_FILTERS
  });

export const HANDLE_FILTER_CLICK = "HANDLE_FILTER_CLICK",
  handleFilterClick = selectedFilter => ({
    "payload": selectedFilter,
    "type": HANDLE_FILTER_CLICK
  });

export const setFilters = selectedFilters => ({
  selectedFilters,
  "type": "SET_FILTERS"
});

export const FETCH_CUSTOMER_REQUESTS = 'FETCH_CUSTOMER_REQUESTS', // eslint-disable-line
  fetchCustomerRequests = () => {
    let request = {};
    if (API.CUSTOMER_ID) {
      request = $.ajax({
        "headers": API.authHeaders(),
        "method": "GET",
        "url": `/api/v${API.API_VERSION}/wish_list/requests_for_customer`
      });
    }

    return {
      "payload": request,
      "type": FETCH_CUSTOMER_REQUESTS
    };
  };

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST", // eslint-disable-line
  updateCustomerRequest = (productDescriptorId, status) =>
    ({
      "payload": {
        "id": productDescriptorId,
        "value": status
      },
      "type": UPDATE_CUSTOMER_REQUEST
    });

export const FETCH_PD_PRICES = "FETCH_PD_PRICES", // eslint-disable-line
  fetchPDPrices = (productDescriptorIds, mySet = null) => {
    const request = $.ajax({
      "data": {
        "ids": productDescriptorIds,
        "my_set": mySet
      },
      "headers": API.authHeaders(),
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/product_descriptors/price_info`
    });

    return {
      "payload": request,
      "type": FETCH_PD_PRICES
    };
  };

export const FETCH_REWARDS_DISCOUNTS = "FETCH_REWARDS_DISCOUNTS", // eslint-disable-line
  fetchRewardsDiscounts = (shipmentId) => {
    const request = axios.get(
      `/api/v${API.API_VERSION}/customers/${API.CUSTOMER_ID}/shipments/${shipmentId}/products/rewards_discounts.json`, // eslint-disable-line max-len
      {"headers": API.authHeaders()}
    );

    return {
      "payload": request,
      "type": FETCH_REWARDS_DISCOUNTS
    };
  };

export const SET_OFFSET = "SET_OFFSET",
  setOffset = offset => ({
    "payload": offset,
    "type": SET_OFFSET
  });

export const UPDATE_PRODUCT_BEING_SWAPPED = "UPDATE_PRODUCT_BEING_SWAPPED",
  updateProductBeingSwapped = product => ({
    "payload": product,
    "type": UPDATE_PRODUCT_BEING_SWAPPED
  });

export const SET_MY_SET_SELECTED_PRODUCTS = "SET_MY_SET_SELECTED_PRODUCTS",
  setMySetSelectedProducts = products => ({
    "payload": products,
    "type": SET_MY_SET_SELECTED_PRODUCTS
  });

export const SET_NOT_PURCHASED_PRODUCTS = "SET_NOT_PURCHASED_PRODUCTS",
  setNotPurchasedProducts = products => ({
    "payload": products,
    "type": SET_NOT_PURCHASED_PRODUCTS
  });

export const UPDATE_CREDIT = "UPDATE_CREDIT",
  updateCredit = credit => ({
    "payload": credit,
    "type": UPDATE_CREDIT
  });

export const ADD_PROMOTION = "ADD_PROMOTION",
  addPromotion = promotion => ({
    "payload": promotion,
    "type": ADD_PROMOTION
  });

export const REMOVE_PROMOTION = "REMOVE_PROMOTION",
  removePromotionByCode = promoCode => ({
    "payload": promoCode,
    "type": REMOVE_PROMOTION
  });

export const SET_DISPLAY_NEW_TIER_NOTIFICATION = "SET_DISPLAY_NEW_TIER_NOTIFICATION",
  setDisplayNewTierNotification = newTierName => ({
    "payload": newTierName,
    "type": SET_DISPLAY_NEW_TIER_NOTIFICATION
  });

export const SET_POST_PURCHASE_MODAL = "SET_POST_PURCHASE_MODAL",
  setPostPurchaseModal = postPurchasePromo => ({
    "payload": postPurchasePromo,
    "type": SET_POST_PURCHASE_MODAL
  });

export const FETCH_CUSTOMER_CREDITS = 'FETCH_CUSTOMER_CREDITS', // eslint-disable-line
  fetchCustomerCredits = (customerId) => {
    let request = {};
    if (API.CUSTOMER_ID) {
      request = $.ajax({
        "headers": API.authHeaders(),
        "method": "GET",
        "url": `/api/v${API.API_VERSION}/customer_credits/show?customer_id=${customerId}`
      });
    }

    return {
      "payload": request,
      "type": FETCH_CUSTOMER_CREDITS
    };
  };

export const SHOW_ERROR = "SHOW_ERROR",
  showError = errorMessage => ({
    "payload": {
      "displayError": true,
      errorMessage
    },
    "type": SHOW_ERROR
  });

export const HIDE_ERROR = "HIDE_ERROR",
  hideError = () => ({
    "payload": {
      "displayError": false,
      "errorMessage": ""
    },
    "type": HIDE_ERROR
  });
