/* eslint one-var: "off" */

const validateString = str => typeof str === "string";

// Check it's an object, not an array, and not null
const validateObject = input => typeof input === "object" &&
  !Array.isArray(input) &&
  Boolean(input);

const camelizeString = (snake) => {
  if (!validateString(snake)) {
    return null;
  }
  return snake.replace(/_([a-z])/g, (_match, sub) => sub.toUpperCase());
};

const camelizeKeysOfObject = (inObj) => {
  if (!validateObject(inObj)) {
    return inObj;
  }
  return Object.keys(inObj).reduce(
    (returnObj, key) => {
      returnObj[camelizeString(key)] = inObj[key];
      return returnObj;
    }, {}
  );
};

const isAnObjectWithContents = input => validateObject(input) && Object.keys(input).length > 0;

const camelizeKeysOfObjectDeep = inObj => Object.keys(inObj).reduce(
  (returnObj, key) => {
    const inValue = inObj[key],
      outValue = isAnObjectWithContents(inValue)
        ? camelizeKeysOfObjectDeep(inValue)
        : inValue;
    returnObj[camelizeString(key)] = outValue;
    return returnObj;
  // `returnObj` is initialized with an empty object literal
  }, {}
);

export default {
  "keysOfObject": camelizeKeysOfObject,
  "keysOfObjectDeep": camelizeKeysOfObjectDeep,
  "string": camelizeString,
  validateObject
};
