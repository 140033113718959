import Error from "./Error";
import PropTypes from "prop-types";
import React from "react";

export default class ErrorBoundary extends React.PureComponent {
  static propTypes = {"children": PropTypes.object.isRequired};

  constructor (props) {
    super(props);

    this.state = {"hasError": false};
  }

  componentDidCatch (error, info) {
    window.airbrake.notify({
      error,
      "params": {info}
    });
    this.setState({"hasError": true});
  }

  render () {
    const {children} = this.props;
    const {hasError} = this.state;

    return hasError
      ? <Error />
      : children;
  }
}
