import React from "react";

const Error = () => (
  <div className="page_wrapper">
    <div className="page_content error_page">
      <h5>
        {"Sorry, we can't find the page you're looking for :("}
      </h5>
      <a
        className="btn btn-md btn-primary centered"
        href="/"
      >
        Take Me to the Homepage
      </a>
    </div>
  </div>
);

export default Error;
